@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;1,100;1,400&display=swap");

:root {
  --main-bg-color: #242424;
  --secondary-bg-color: #1c1d1d;
  --tri-bg-color: #f7f7f7;
  --accent-bg-color: #198f91;
  --heading-color: rgb(221, 221, 221);
}
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", san-serif;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--accent-bg-color);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--accent-bg-color);
}
.btn-modal {
  background-color: var(--accent-bg-color);
  color: var(--tri-bg-color);
  padding: 0.5em 0.7em;
  width: 90%;
  margin: 0 auto;
  font-size: 1rem;
  border-radius: 3px;
  scale: 100%;
  transition: all 0.2s ease-in-out;
  position: absolute;
  bottom: 20px;
  left: 5%;
}
.btn-modal:hover {
  background-color: #136566;
  scale: 105%;
}
/*-------------------------------
// ASIDE SECTION - START 
-------------------------------*/
#aside {
  display: none;
  font-size: 18px;
}
.aside-nav {
  display: block;
}
.aside-nav ul {
  text-align: left;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aside-nav ul li {
  padding-left: 1em;
  list-style-type: none;
}
.aside-nav ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 0.3em;
}
.home-anchor::before,
.about-anchor::before,
.portfolio-anchor::before {
  padding-left: 0.3em;
  padding-right: 1em;
}
.aside-nav ul li a:hover {
  color: var(--accent-bg-color);
}
#aside h2 {
  background-color: var(--accent-bg-color);
  color: #fff;
  padding: 0.8em 0em;
  font-weight: 400;
  font-size: 18px;
  top: 194px;
  width: 100%;
  position: absolute;
}
.fa-address-card,
.fa-house,
.fa-briefcase,
.fa-graduation-cap,
.fa-file {
  font-size: 14px;
  margin-right: 15px;
}
#aside img {
  width: 210px;
}
.aside-bottom {
  position: fixed;
  bottom: 10px;
  left: 20px;
}
#made {
  color: #ffffff8e;
}
/*------------------------------
// MAIN-NAV SECTION - START 
--------------------------------*/
#main-nav {
  display: none;
}
#main-nav a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  width: 33%;
}
#main-nav a:hover {
  color: var(--accent-bg-color);
}
.fa-envelope {
  padding: 0.5em;
  background-color: var(--accent-bg-color);
  font-size: 34px;
  margin-right: 0;
  color: var(--tri-bg-color);
}
/*------------------------
// INTRO SECTION - START 
--------------------------*/
#intro {
  display: flex;
  align-content: center;
  height: auto;
  padding-bottom: 3em;
  color: #fff;
  font-size: 18px;
  background-color: rgb(133, 133, 133);
  background-blend-mode: overlay;
  background-image: url(/public/assets/img/hero.webp);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
#intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 2em;
}
#intro h1 {
  font-size: 3em;
}
#intro span {
  color: var(--accent-bg-color);
}

.btn-two {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-bg-color);
  color: #fff;
  height: 50px;
  max-width: 310px;
  text-align: center;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  border-radius: 2px;
  position: relative;
}
#intro span.landing--h--letter,
#made span.landing--h--letter {
  color: var(--tri-bg-color);
  font-size: 1.2rem;
  transition: all 300ms ease-out;
  position: static;
}
#intro span.landing--h--letter:hover,
#made span.landing--h--letter:hover {
  font-size: 1.8rem;
  margin-top: 0;
  filter: blur(3px);
  opacity: 0;
}
#intro span.landing--h--letter:hover {
  color: var(--accent-bg-color);
}
#made span.landing--h--letter:hover {
  color: var(--main-bg-color);
}

#intro span.hidden-letter {
  color: transparent;
  font-size: 0.2rem;
}
/*------------------------------
// ABOUT ME SECTION - START 
-------------------------------*/
#about {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 6em 2em;
  gap: 2em;
  height: auto;
}
#about .img-cont {
  margin: 0 auto;
  max-width: 500px;
  padding: 3em 0em 0em 0em;
}
#about-para {
  min-width: 300px;
}
#about h1,
#experience h1,
#portfolio h1 {
  padding: 1em 0 0 0;
  color: var(--heading-color);
  font-size: 3rem;
  font-weight: 600;
}
#about h3,
#experience h3,
#portfolio h3 {
  font-size: 1.5rem;
}
#social-cont ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: auto;
  list-style-type: none;
  gap: 1em;
  font-weight: 600;
}
.fa-plus {
  font-size: 20px;
  color: var(--accent-bg-color);
  margin-right: 10px;
}
#icon0,
#icon1,
#icon2,
#icon3,
#icon4 {
  transition: transform 0.2s ease-in-out;
}
/*-------------------------------
// EXPERIENCE SECTION - START 
---------------------------------*/
#experience {
  padding: 4em 0em;
  background-color: var(--tri-bg-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
}
#experience-div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
.card-container-1,
.card-container-2 {
  padding: 2em 0em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 1200px;
  gap: 3em;
  /* margin: 0em 1.5em; */
}
.card-container-2 {
  width: 100%;
}
.card-container-2::before,
#experience::after {
  content: "|";
  color: var(--accent-bg-color);
  font-weight: 600;
}
.card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2em;
  z-index: 2;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  text-align: left;
  border: none;
}
.fa-ghost,
.fa-user-graduate,
.fa-scroll {
  color: var(--accent-bg-color);
  position: relative;
  top: 5px;
  font-size: 25px;
}
.card-box {
  padding-left: 1em;
  align-items: flex-start;
}
.card-title {
  font-size: 22px;
  font-weight: 600;
}
.card-subtext {
  font-size: 16px;
  font-weight: 500;
}
.card-para {
  font-size: 14px;
}
.card a {
  color: var(--accent-bg-color);
}
#division1 {
  height: 50%;
  background-color: var(--heading-color);
  position: absolute;
  width: 5px;
  left: 200px;
  top: 250px;
}
#division2 {
  height: 25%;
  background-color: var(--heading-color);
  position: absolute;
  width: 5px;
  right: 200px;
  top: 2000px;
}
/*-----------------------------
// PORTFOLIO SECTION - START 
------------------------------*/
#portfolio {
  background-color: var(--tri-bg-color);
  display: flex;
  flex-direction: column;
  padding: 0em 1em;
  justify-content: center;
  align-content: center;
  align-items: center;
}
#port-header {
  display: flex;
  flex-direction: column;
  background-color: var(--tri-bg-color);
}
#port-nav {
  padding: 3em 0em;
  gap: 3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
}
#port-nav ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
}
#port-nav ul li {
  margin: 1em;
  text-align: center;
}
button {
  border: none;
}
#port-nav ul li button {
  padding: 1em;
  border-bottom: solid var(--heading-color);
  text-decoration: none;
  color: var(--secondary-bg-color);
  font-size: 16px;
  background-color: transparent;
}
#port-nav ul li button:focus {
  border-bottom: solid var(--accent-bg-color);
  color: var(--accent-bg-color);
}
#port-nav ul li button:hover {
  color: var(--accent-bg-color);
}
/*--------------------------------
// MOBILE COLLAGE SECTION - START 
---------------------------------*/
#mobile-ui-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  place-self: center;
  gap: 0;
}
#mobile-ui-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-self: center;
  padding: 0;
}

.ui-img-card {
  -webkit-user-drag: none;
  width: 320px;
  height: 650px;
  transition: all ease-in-out 200ms;
}
.ui-img-card:hover {
  scale: 105%;
}
/*------------------------
// COLLAGE SECTION - START 
-------------------------*/
#All {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.filter-btn {
  font-size: 1.3rem;
  font-weight: 400;
}
.filter-btn::after {
  content: "|";
  position: relative;
  top: -3px;
  font-size: 0.9rem;
  margin-left: 1rem;
}
.filter-btn:hover {
  color: var(--accent-bg-color);
}
.card-container {
  max-width: 350px;
  width: 100%;
  max-height: 400px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.collage-card {
  height: auto;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  align-items: center;
  align-content: center;
  margin: 1em;
  box-sizing: border-box;
  z-index: 0;
  border: solid 3px var(--accent-bg-color);
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
}
.collage-img {
  box-sizing: border-box;
  min-width: 350px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  max-height: 700px;
  overflow: hidden;
  object-fit: cover;
  scale: 100%;
  transition: 0.2s ease-in-out;
  z-index: 0;
  margin: 0 auto;
}
.collage-card img:hover {
  cursor: pointer;
  scale: 103%;
  transition: 0.2s ease-in-out;
  z-index: 0;
}
/* .toggleAppearance {
  display: none;
  position: relative;
  top: -400px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  gap: 0.5em;
  height: 200px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.766);
  backdrop-filter: blur(8px);
}
.toggleAppearance a {
  color: var(--tri-bg-color);
  text-decoration: none;
  font-weight: 900;
  font-size: 24px;
  max-width: 270px;
  width: 100%;
  padding: 0.5em 1em 0.5em 1em;
  line-height: 24px;
}
.toggleAppearance p {
  color: var(--tri-bg-color);
  font-size: 18px;
} */
/* .invisible {
  display: none;
} */
.appear {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--tri-bg-color);
  padding: 2rem 0;
  gap: 2em;
  width: 100%;
  height: 100%;
  z-index: 5;
  margin-bottom: 0px;
  background-color: var(--secondary-bg-color);
  user-select: none;
}
.appear a {
  color: #198f91;
  font-size: 1.2em;
  line-height: 1.2em;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}
.appear a:hover {
  color: #fff;
  font-size: 1.3em;
  line-height: 1.1em;
  transition: 0.2s ease-in-out;
}
.fa-rectangle-xmark {
  position: relative;
  right: 0;
  top: 0;
  color: #ffffff;
  font-size: 2em;
  text-align: right;
}
.fa-rectangle-xmark:hover {
  color: #198f91;
}
.fa-angle-down {
  font-size: 2em;
  color: var(--heading-color);
}
/*---------------------------
// GALLERY SECTION - START 
-------------------------------*/
#gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0em 0 5em 0;
}
/*------------------------------
// BURGER NAV
--------------------------------*/
.topnav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}
.topnav a {
  background-color: var(--accent-bg-color);
  color: var(--accent-bg-color);
  text-decoration: none;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
}
.bars:hover {
  color: var(--accent-bg-color);
}
#myLinks a {
  color: var(--tri-bg-color);
  text-decoration: none;
  background-color: transparent;
  border-bottom: solid 3px transparent;
  padding: 0.5em 1em;
  position: relative;
  left: 2%;
  top: -80px;
  width: 80%;
  text-align: center;
}
#myLinks a:hover {
  border-bottom: solid 3px var(--tri-bg-color);
  animation: slideIn 300ms ease-in-out;
}
#myLinks a:active {
  color: var(--accent-bg-color);
  border-bottom: solid 3px var(--accent-bg-color);
}
#myLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2em;
  width: 100vw;
  height: 100vh;
  background-color: var(--main-bg-color);
  z-index: 998;
  overflow: hidden;
}
.btn {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  animation: fadeIn 500ms ease-in-out;
}
.exe {
  background-color: var(--main-bg-color);
  color: var(--accent-bg-color);
  z-index: 9999;
  clear: both;
}
.exe:hover {
  background-color: var(--main-bg-color);
  color: var(--accent-bg-color);
}
.bars {
  color: var(--accent-bg-color);
}
.mobile-nav-footer {
  display: flex;
  flex-direction: column;
  gap: 2em;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 100px;
}
.mobile-nav-footer a {
  width: 100%;
  position: relative;
  right: 0;
  margin: 0 auto;
  color: white;
  font-size: 1rem;
  background-color: var(--accent-bg-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 1em 2em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    border-bottom: solid 3px var(--tri-bg-color);
  }
  50% {
    border-bottom: solid 3px var(--accent-bg-color);
  }
  100% {
    border-bottom: solid 3px var(--tri-bg-color);
  }
}
/*------------------------
// UP ARROW
-------------------------*/
#arrow-container {
  position: fixed;
  top: 80%;
  right: 0;
  font-size: 1.5rem;
  font-weight: 100;
  z-index: 99;
  background-color: var(--accent-bg-color);
  opacity: 0.7;
  text-decoration: none;
  cursor: pointer;
}
.arrow-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.2em;
}
.slant {
  background-color: var(--tri-bg-color);
  width: 10px;
  height: 3px;
  border-radius: 30px;
}
.left-slant {
  position: relative;
  transform: rotate(45deg);
  left: 8px;
  top: 0px;
}
.right-slant {
  position: relative;
  transform: rotate(-45deg);
  top: 0px;
  right: 8px;
}
.arrow-link:hover {
  animation: arrowBounce 700ms infinite ease-in-out;
}
/* arrow animation */
@keyframes arrowBounce {
  0% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(3px);
  }
}
/* --------------------- 
        MEDIA QUERIES
------------------------ */
@media screen and (min-width: 1205px) {
  #division1 {
    height: 75%;
  }
  #division2 {
    top: 450px;
    height: 45%;
  }
}
@media screen and (min-width: 987px) {
  #intro-container {
    width: 60%;
  }
  #aside {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 210px;
    min-width: 210px;
    text-align: center;
    background-color: var(--secondary-bg-color);
  }
  #main-nav,
  #intro,
  #about,
  #experience,
  #portfolio {
    margin-left: 210px;
  }
  .toggleAppearance {
    max-width: 300px;
  }
  #experience-div {
    flex-direction: row;
    gap: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .topnav {
    display: none;
  }
  .toggleAppearance {
    max-width: 300px;
  }
  #All {
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  #port-nav ul li a {
    font-size: 16px;
  }
  #port-nav ul {
    flex-direction: row;
  }
  #port-nav ul li {
    margin: 0;
  }
  #gallery {
    justify-content: flex-start;
    padding-left: 2em;
  }
  .card-container-2::before {
    content: "-";
    position: relative;
    left: 0;
  }
  .card {
    width: 450px;
  }
  #intro {
    height: 100vh;
  }
  #intro-container {
    width: 75%;
    padding: 0 6em;
  }
  #about {
    flex-wrap: nowrap;
    padding: 7em;
  }
  #social-cont ul {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
  }
  #main-nav {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: var(--secondary-bg-color);
  }
}
/* ITEM MODAL */
#modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #1c1d1dee;
  backdrop-filter: blur(13px);
  margin: 0 auto;
}
.modal__container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 1%;
  width: 98%;
  gap: 2rem;
  padding: 1rem 2rem 2rem 2rem;
  height: 98%;
  max-width: 550px;
  background-color: #1c1d1dfa;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(var(--secondary-bg-color), 0.7);
  border: solid 2px var(--accent-bg-color);
  border-radius: 20px;
}
.modal__close-btn-container {
  width: 100%;
  text-align: right;
}
.modal__close-btn {
  background-color: transparent;
  color: white;
  font-size: 2rem;
  position: relative;
  right: -10px;
  top: 0px;
}
.modal__close-btn:hover {
  color: var(--accent-bg-color);
}
.modal__link-btn-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.modal__btn,
.modal__web-btn {
  padding: 1rem 2rem;
  border: solid var(--accent-bg-color) 2px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--accent-bg-color);
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}
.modal__btn:hover {
  color: white;
  background-color: var(--accent-bg-color);
}
.modal__web-btn {
  color: white;
  background-color: var(--accent-bg-color);
}
.modal__web-btn:hover {
  color: white;
  background-color: var(--main-bg-color);
}
.modal-open {
  display: flex;
}
.modal-closed {
  display: none;
}
.modal__info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  overflow-y: scroll;
}
.modal__info-container::after {
  content: "";
  background-color: var(--accent-bg-color);
  width: 10%;
  height: 1px;
  margin: 0 auto;
  position: absolute;
  top: 72%;
  left: 45%;
}
.modal__info-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.modal__info-container::-webkit-scrollbar {
  width: 0px;
}
.modal__info-container::-webkit-scrollbar-track {
  background: transparent;
}
.modal__info-container::-webkit-scrollbar-thumb {
  background: var(--accent-bg-color);
  border-radius: 5px;
}
.modal__info-container::-webkit-scrollbar-thumb:hover {
  background: var(--accent-bg-color);
}
.modal__info-column {
  display: flex;
  flex-direction: column;
}
.modal__title {
  font-size: 2.7rem;
  font-weight: 600;
}
.modal__para {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-weight: 400;
  color: var(--accent-bg-color);
}
.modal__about-project h3 {
  font-weight: 400;
  font-size: 1.2rem;
}
.modal__about-project p {
  font-weight: 300;
}
